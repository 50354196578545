import React from "react"
import { Helmet } from "react-helmet";

const Pixels = () => (
  <>
  <Helmet>
  <link
    href="https://fonts.googleapis.com/css?family=Josefin+Sans:400,700&display=swap"
    rel="stylesheet"
  />
{/* <script
  async
  src="https://www.googletagmanager.com/gtag/js?id=AW-837012328"
/>
<script>
  {gtag}
</script>
<script
  async
  src="https://www.googletagmanager.com/gtag/js?id=UA-89937538-5"
/>
<script>
  {UA899375385}
</script>
<script>
  {tag12125}
</script> */}
</Helmet>

{/* <script
      dangerouslySetInnerHTML={{
        __html: `(function l(d) { var site = '12125', page = 'homepage', s, er = d.createElement('script'); er.type = 'text/javascript'; er.async = true; er.src = 'https://o2.eyereturn.com/?site=' + site + '&page=' + page; s = d.getElementsByTagName('script')[0]; s.parentNode.insertBefore(er, s); } )(document);`,
      }}
    />
      <script
  async
  src="https://www.googletagmanager.com/gtag/js?id=AW-837012328"
/>
<script
  dangerouslySetInnerHTML={{
    __html: gtag,
  }}
/>

    <script
  async
  src="https://www.googletagmanager.com/gtag/js?id=UA-89937538-5"
/>
<script
  dangerouslySetInnerHTML={{
    __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-89937538-5');`,
  }}
  type="text/javascript"
/> */}
</>
)

export default Pixels

const gtag = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-837012328');
`;

const UA899375385 = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-89937538-5');`

const tag12125 = `(function l(d) { var site = '12125', page = 'homepage', s, er = d.createElement('script'); er.type = 'text/javascript'; er.async = true; er.src = 'https://o2.eyereturn.com/?site=' + site + '&page=' + page; s = d.getElementsByTagName('script')[0]; s.parentNode.insertBefore(er, s); } )(document);`