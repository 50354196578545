import React, { useState } from "react"
import { useTheme } from "emotion-theming"
import { useTransition, animated } from "react-spring"
import VisuallyHidden from "@reach/visually-hidden"
import { scroller } from "react-scroll"

import { Wrapper, MenuLink, Content, Overlay } from "./styles"

import { Container } from "@bigif/components/grid"
import Button from "@bigif/components/button"
import Burger from "@bigif/svgs/burger"
import Logo from "@bigif/svgs/logo"
import styled from "@emotion/styled"

animated.Overlay = animated(Overlay)
animated.Content = animated(Content)

const PositionWrapper = styled.div`
position: relative;
top: 40px;
`

const Header = () => {
  const theme = useTheme()
  const [isOpen, setOpen] = useState(false)

  const dialogTransition = useTransition(isOpen, null, {
    from: { y: -100, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: 100, opacity: 0 },
  })

  const goTo = anchor => {
    setOpen(false)
    scroller.scrollTo(anchor, {
      duration: 1000,
      delay: 100,
      smooth: true,
    })
  }

  return (
    <>
      <Wrapper p={3} isOpen={isOpen}>
        <Button variant="none" onClick={() => setOpen(!isOpen)}>
          <VisuallyHidden>Basculer le menu</VisuallyHidden>
          <Burger
            color={isOpen ? theme.colors.primary[1] : theme.colors.text}
          />
        </Button>
        <Logo color={isOpen ? theme.colors.primary[1] : theme.colors.text} />
        <Button
          variant={isOpen ? "smPrimary" : "outlinePrimary"}
          as="a"
          href="https://thebigif.ca/"
        >
          <VisuallyHidden>Basculer la langue </VisuallyHidden>
          EN
        </Button>
      </Wrapper>
      {dialogTransition.map(
        ({ item: show, key, props: { y, opacity } }) =>
          show && (
            <animated.Overlay
              key={key}
              style={{
                transform: y.interpolate(y => `translate3d(0px, ${y}%, 0px)`),
                opacity,
              }}
            >
              <animated.Content
                style={{
                  opacity,
                }}
              >
                  <PositionWrapper>
                <Container>
                  <MenuLink onClick={() => goTo("big-if")}>Si Jamais</MenuLink>
                  <MenuLink onClick={() => goTo("disaster")}>
                    En cas de catastrophe
                  </MenuLink>
                  <MenuLink onClick={() => goTo("cyberattack")}>
                    Cyberattaque
                  </MenuLink>
                  <MenuLink onClick={() => goTo("broker")}>
                    Trouver un courtier
                  </MenuLink>
                  <MenuLink onClick={() => goTo("partners")}>
                    Partenaires
                  </MenuLink>
                  <MenuLink onClick={() => goTo("stats")}>
                    Statistiques &amp; Ressources
                  </MenuLink>
                </Container>
                </PositionWrapper>
              </animated.Content>
            </animated.Overlay>
          )
      )}
    </>
  )
}

export default Header
