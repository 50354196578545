import React from "react"
import css from "@styled-system/css"
import { useScrollYPosition } from "react-use-scroll-position"
import { useTranslation } from "react-i18next"

const Logo = props => {
  const scrollY = useScrollYPosition()
  const { i18n } = useTranslation()
  const scrollOffset = 500
  let opacity = props.isOpen ? 1 : 0
  if (scrollY > scrollOffset) {
    opacity = 1
  }

  const { language } = i18n
  let atts = false
  if (language === "fr-CA") {
    atts = {
      d:
        "M3.399 54.928h3.895v7.525c0 .192-.03.385-.103.563-.296.8-1.289 2.888-3.792 2.888-2.607 0-3.155-1.081-3.155-1.081l1.037-3.11s2.074.695 2.118-.593c.044-1.289 0-6.191 0-6.191zm15.908 10.295l-3.051-10.294h-4.977L8.124 65.222h4.429V63.74h2.221v1.482h4.533zm-6.206-3.94l.622-2.415.474 2.415H13.1zm33.607 3.94l-3.051-10.294H38.68l-3.155 10.294h4.429V63.74h2.222v1.482h4.532zm-6.206-3.94l.622-2.415.474 2.415h-1.096zm-20.159 3.865V54.914h5.614l1.481 5.347 1.718-5.347h5.466v10.323h-3.836v-5.495l-1.704 5.495h-3.229l-1.673-5.435v5.435h-3.837v-.088zm27.372-10.293h3.748v10.442h-3.748V54.855zm12.575 3.214l1.067-1.6c.103-.148.074-.356-.06-.489-.473-.43-1.718-1.288-4.265-1.288-3.377 0-3.688 2.977-3.688 2.977s-.415 2.488 3.11 3.717c.282.104.489.193.622.297a.38.38 0 01-.133.666c-.356.089-1.052.074-2.31-.622-.164-.089-.371-.06-.475.089l-1.244 1.526a.383.383 0 00.015.503c.489.519 1.837 1.615 4.592 1.615 3.391 0 4.087-2.696 4.162-3.007 0-.015 0-.045.014-.06.015-.296.104-2.903-2.977-3.688-.4-.103-.681-.251-.844-.444a.38.38 0 01.015-.489c.133-.148.237-.207.592-.177.311.03.963.37 1.348.592.133.104.34.044.46-.118zM35.94 16.537l5.021-7.54a1.766 1.766 0 00-.266-2.295C38.488 4.672 32.548.6 20.58.6 4.643.6 3.131 14.67 3.131 14.67S1.148 26.417 17.796 32.238c1.303.474 2.296.889 2.962 1.378 1.185.873.8 2.77-.637 3.14-1.688.444-5.006.355-10.916-2.948a1.795 1.795 0 00-2.266.415l-5.91 7.228a1.8 1.8 0 00.074 2.355c2.281 2.43 8.665 7.613 21.684 7.613 16.012 0 19.3-12.708 19.626-14.175l.044-.266c.089-1.437.474-13.7-14.086-17.433-1.91-.474-3.229-1.17-4.014-2.089-.577-.666-.518-1.688.075-2.34.651-.711 1.155-.978 2.77-.815 1.495.163 4.546 1.763 6.368 2.785a1.753 1.753 0 002.37-.548zM45.48.614c-.06.193 0 32.245 0 32.245h15.833V.614H45.48zm8.1 50.834a8.028 8.028 0 100-16.056 8.028 8.028 0 000 16.056z",
      width: 62,
      height: 66,
    }
  }
  if (language === "en-CA") {
    atts = {
      d:
        "M0 31.72l.049 16.695h16.206V14.977H0V31.72zm4.54 20.698c-7.566 4.051-4.98 15.279 3.563 15.279 6.2 0 9.959-6.688 6.932-12.155-2.001-3.466-7.03-4.98-10.495-3.124zm16.621-37.45h39.226v16.765h-19.49v5.865h15.777v15.103H40.945V68H21.26l-.098-53.032zM9.378.198v3.375H6.6v6.897H2.78V3.573H0V.198h9.378zm5.012 3.523h2.332V.198h3.82V10.47h-3.82V6.947H14.39v3.523h-3.82V.198h3.82v3.523zM26.1 7.542h4.416v2.928h-8.237V.198h7.94v2.928h-4.12v.744h3.822v2.928H26.1v.744zm15.68-2.431s2.332.347 2.332 2.58c0 1.588-1.34 2.927-4.962 2.927-2.332 0-4.416-.148-4.416-.148V.198S36.768.05 39.05.05c3.274 0 4.614 1.34 4.614 2.877 0 1.737-1.885 2.134-1.885 2.134v.05zm-3.226-1.092h.497c.496 0 .794-.298.794-.645 0-.347-.298-.645-.794-.645h-.497v1.29zm1.489 3.225c0-.446-.298-.744-.893-.744h-.596V7.99h.596c.595 0 .893-.298.893-.745zm9.23 3.226H45.45V.198h3.821V10.47zm7.591-3.374V5.359h3.523v3.573s-1.34 1.736-4.267 1.736c-3.077 0-5.409-2.332-5.409-5.359S53.093 0 56.17 0c2.778 0 4.118 1.588 4.118 1.588L58.55 4.367s-.893-.794-1.885-.794-1.737.744-1.737 1.736c0 .993.744 1.737 1.737 1.737.05.05.198.05.198.05z",
      width: 61,
      height: 68,
    }
  }

  if (atts) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="68"
        fill="none"
        viewBox={`0 0 ${atts.width} ${atts.height}`}
        css={css({
          opacity,
          transition: "opacity 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955)",
        })}
        key={atts}
        {...props}
      >
        <path
          key={atts.d}
          fill={props.color ? props.color : "#fff"}
          d={atts.d}
        ></path>
      </svg>
    )
  }

  return null
}

export default Logo
